<template>
  <section class="searchable-table">
    <slot name="search">
      <SearchableTableQueryInput
        :class="searchClass"
        v-if="searchEnabled"
        v-model="query"
        v-bind:createCommand="createCommand"
        v-on:create="onButtonCreateClick"
      >
        <template #statistics v-if="$scopedSlots.statistics">
          <slot name="statistics" :itemsInPage="itemList.length"></slot>
        </template>
        <template #customFilters>
          <slot name="customFilters"></slot>
        </template>
        <template #extraButtons>
          <slot name="extraButtons"></slot>
        </template>
      </SearchableTableQueryInput>
    </slot>
    <slot name="paginationBefore"></slot>
    <TablePaginationNav
      v-if="pagination"
      v-show="showPagination"
      v-model="pageItems"
      :items="sortedList"
      :pageSize="pageSize"
      :nItems="nItemsForPagination"
      :multiColumnOrder="multiColumnOrder"
      :pageJump="pageJump"
      :serverPagination="pagination == 'server'"
      @loadNextPage="$emit('loadNextPage')"
      @pageChanged="$emit('pageChanged', $event)"
      @showSortForm="onShowSortForm"
      :style="{ 'margin-top': searchEnabled ? '30px' : '6px' }"
    />
    <slot name="paginationAfter"></slot>
    <div
      :style="{
        'margin-top': showPagination || !searchEnabled ? '2px' : '30px',
        position: 'relative',
        clear: 'both'
      }"
    >
      <table
        :class="tableClass"
        v-bind:id="tableId ? tableId : 'tb_' + new Date().getTime()"
        ref="table"
      >
        <thead>
          <tr class="sortable">
            <th
              v-if="isMultiSelection"
              class="text-primary text-center clicable"
              @click.stop.prevent="toggleSelection"
              style="max-width: 20px"
            >
              <i :class="checkAllClass"></i>
            </th>
            <th v-if="draggable" class="draggable-th text-primary">#</th>
            <template v-for="(field, ix) in fields">
              <tho
                v-if="isVisible(field)"
                :key="ix"
                :field="field"
                :name="field.name"
                :data-testid="`th-${field.name}`"
                :title="field.title"
                :nowrap="true"
                :style="style(null, field)"
                :tooltip="field.tooltip || ''"
                v-on:sort="onSort"
              />
            </template>
            <th v-if="itemCommands.length" class="text-center">
              <!-- Split button -->
              <div class="btn-group bulk-command" v-if="bulkCommands.length">
                <span>
                  {{ $t("options") }}
                </span>
                <button
                  type="button"
                  class="btn btn-link dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                  <li v-for="command in bulkCommands" :key="command.name">
                    <a
                      href="#"
                      :data-testid="command.name + '-all'"
                      @click.prevent="onBulkCommand(command, $event.target)"
                      >{{
                        `${$tc("all")}: ${$tc(
                          command.bulk.desc ||
                            (typeof command.bulk == "string"
                              ? command.bulk
                              : command.title)
                        )}`
                      }}</a
                    >
                  </li>
                </ul>
              </div>
              <span v-else>{{ $t("options") }}</span>
            </th>
          </tr>
        </thead>
        <draggable
          v-if="draggable"
          v-bind="draggable.options"
          v-on="draggable.listeners"
          draggable=".clicable-row"
          handle=".drag-handle"
          v-model="sortedList"
          @update="$emit('reorder', sortedList)"
          tag="tbody"
          class="table-body"
        >
          <template v-for="(item, ix) in itemList">
            <tr
              data-testid="item"
              v-bind:key="ix"
              v-bind:class="[
                $attrs.disabled ? 'text-info' : 'text-info clicable-row',
                {
                  active: showSelected && curPos > -1 && ix == curPos
                },
                getClass(item)
              ]"
              v-on:click.prevent.stop="select(ix, item)"
            >
              <td v-if="isMultiSelection" class="text-primary">
                <i class="fa fa-square-o"></i>
              </td>
              <td v-if="draggable" class="drag-handle">
                <span
                  class="glyphicon glyphicon-move"
                  aria-hidden="true"
                ></span>
              </td>
              <td
                v-for="(field, ix2) in fields"
                :data-testid="field.name"
                v-bind:key="ix2"
                v-bind:style="style(item, field)"
              >
                <slot :name="field.name" :item="item">
                  <span class="text-default">{{ value(item, field) }}</span>
                </slot>
              </td>
              <template v-if="itemCommands.length">
                <td
                  class
                  v-on:click.prevent.stop="
                    () => {
                      return false;
                    }
                  "
                >
                  <div class="action-cell">
                    <template v-for="(command, ix3) in itemCommands">
                      <div
                        class="btn-sep"
                        v-if="command.name == '-'"
                        v-bind:key="ix3"
                      ></div>
                      <button
                        class="btn btn-xs btn-default btn-action"
                        tabindex="-1"
                        :data-testid="command.name"
                        v-else
                        v-bind:key="ix3"
                        v-bind:title="commandTitle(command, item)"
                        v-bind:disabled="!isCommandEnabled(command, item)"
                        v-on:click.prevent.stop="
                          onButtonCommandClick(ix, command, item, $event.target)
                        "
                      >
                        <Icon v-bind:name="commandIcon(command, item)" />
                      </button>
                    </template>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </draggable>
        <tbody v-else class="table-body">
          <template v-if="tree">
            <template v-for="node in treeItems">
              <template v-if="!node.parent || isOpen(node.parent)">
                <tr
                  class="group-row no-select"
                  :style="fieldAggregation(node).style"
                  :key="node.name"
                >
                  <template v-for="(field, ic) in fields">
                    <td
                      v-if="field.visible"
                      :key="field.name"
                      :style="field.aggregation.style"
                    >
                      <div>
                        <span
                          v-if="ic == 0"
                          class="clicable"
                          @click.stop.prevent="toggleTreeNode(node.name)"
                        >
                          <i
                            class="group-collapse-icon"
                            :class="nodeIcon(node).class"
                            :style="{
                              'padding-left': `${node.level * 5}px`,
                              color: nodeIcon(node).color
                            }"
                          ></i>
                          <span style="padding-left: 10px">
                            <slot
                              :name="`group-cell-${field.name}`"
                              :nodeName="node.name"
                            >
                              {{ node.name.split(".")[node.level] }}
                            </slot>
                          </span>
                        </span>
                        <span v-else>
                          <slot
                            :name="`group-cell-${field.name}`"
                            :nodeName="node.name"
                          >
                          </slot>
                        </span>
                      </div>
                    </td>
                  </template>
                </tr>
                <template v-if="isOpen(node) && node.leaf">
                  <template v-for="(item, ix) in _sortList(node.leaf)">
                    <tr
                      class="clicable"
                      data-testid="item"
                      v-bind:key="node.name + ix"
                      v-bind:class="{
                        active: showSelected && curPos > -1 && ix == curPos
                      }"
                      v-on:click.prevent.stop="select(ix, item)"
                    >
                      <template v-for="(field, ix2) in fields">
                        <td
                          v-if="isVisible(field, item)"
                          :data-testid="field.name"
                          v-bind:key="ix2"
                          v-bind:style="style(item, field)"
                        >
                          <slot :name="field.name" :item="item">
                            <span class="text-default">{{
                              value(item, field)
                            }}</span>
                          </slot>
                        </td>
                      </template>
                      <template v-if="itemCommands.length">
                        <td
                          class
                          v-on:click.prevent.stop="
                            () => {
                              return false;
                            }
                          "
                        >
                          <div class="action-cell">
                            <template v-for="(command, ix3) in itemCommands">
                              <div
                                class="btn-sep"
                                v-if="command.name == '-'"
                                v-bind:key="ix3"
                              ></div>
                              <button
                                class="btn btn-xs btn-default btn-action"
                                tabindex="-1"
                                :data-testid="command.name"
                                v-else
                                v-bind:key="ix3"
                                v-bind:title="commandTitle(command, item)"
                                v-bind:disabled="
                                  !isCommandEnabled(command, item)
                                "
                                v-on:click.prevent.stop="
                                  onButtonCommandClick(
                                    ix,
                                    command,
                                    item,
                                    $event.target
                                  )
                                "
                              >
                                <Icon
                                  v-bind:name="commandIcon(command, item)"
                                />
                              </button>
                            </template>
                          </div>
                        </td>
                      </template>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </template>
          <template v-else v-for="(item, ix) in itemList">
            <tr
              data-testid="item"
              :key="ix"
              :class="[
                $attrs.disabled ? 'text-info' : 'text-info clicable-row',
                item.class ||
                  (showSelected && curPos > -1 && ix == curPos ? 'active' : '')
              ]"
              @click.prevent.stop="select(ix, item)"
            >
              <td
                v-if="isMultiSelection"
                class="text-primary text-center"
                @click.stop.prevent="toggleSelection(ix, item)"
                style="max-width: 20px"
              >
                <i
                  :class="
                    isSelected(item) ? 'fa fa-check-square-o' : 'fa fa-square-o'
                  "
                ></i>
              </td>
              <template v-for="(field, ix2) in fields">
                <td
                  v-if="isVisible(field, item)"
                  :data-testid="field.name"
                  v-bind:key="ix2"
                  v-bind:style="style(item, field)"
                >
                  <slot :name="field.name" :item="item">
                    <span
                      class="text-default"
                      :title="hint(item, field)"
                      v-html="value(item, field)"
                    ></span>
                  </slot>
                </td>
              </template>
              <template v-if="itemCommands.length">
                <td
                  class
                  v-on:click.prevent.stop="
                    () => {
                      return false;
                    }
                  "
                >
                  <div class="action-cell">
                    <template v-for="(command, ix3) in itemCommands">
                      <div
                        class="btn-sep"
                        v-if="command.name == '-'"
                        v-bind:key="ix3"
                      ></div>
                      <button
                        class="btn btn-xs btn-default btn-action"
                        tabindex="-1"
                        :data-testid="command.name"
                        v-else
                        v-bind:key="ix3"
                        v-bind:title="commandTitle(command, item)"
                        v-bind:disabled="!isCommandEnabled(command, item)"
                        v-on:click.prevent.stop="
                          onButtonCommandClick(ix, command, item, $event.target)
                        "
                      >
                        <Icon v-bind:name="commandIcon(command, item)" />
                      </button>
                    </template>
                  </div>
                </td>
              </template>
            </tr>
            <slot :name="`after_row`" :item="item"> </slot>
          </template>
        </tbody>
      </table>
    </div>
    <ColumnOrderSelection
      v-if="sortForm"
      v-on:hide="sortForm = false"
      v-on:sort="onMultiColumnSort"
      v-bind:fields="fields"
    />
    <Spin v-if="loading" />
    <slot name="empty"></slot>
  </section>
</template>

<script>
import tho from "@/components/tho.vue";
import SearchableTableQueryInput from "@/components/searchable-table-query-input.vue";
import Icon from "@/components/icons/icon";
import { isEqual, debounce, pickBy, keys, result } from "lodash";
import Spin from "@/components/spin.vue";

export class InlineFormEditor {
  constructor(items, builder) {
    this.items = items || [];
    (this.items || []).forEach((item, ix) => {
      item._draft = { fields: builder(item, ix) };
      for (var p in item._draft.fields) {
        item._draft.fields[p].error = "";
        item._draft.fields[p].previous = item._draft.fields[p].value;
      }
    });
  }

  // set/get an item property
  prop = (item, attr, value) => {
    if (value !== undefined) {
      if (typeof item._draft.fields[attr].parse == "function") {
        item._draft.fields[attr].parse(item._draft.fields[attr], value);
      } else {
        item._draft.fields[attr].value = value;
      }
      if (item._draft.fields[attr].unique) {
        let ocurrences = keys(
          pickBy(
            this.items.map((i, ix) => {
              i._draft.fields[attr].error = "";
              return {
                value: result(i, `_draft.fields.${attr}.value`),
                ix: ix
              };
            }),
            { value: value }
          )
        );
        if (ocurrences.length > 1) {
          ocurrences.forEach((i) => {
            this.items[i]._draft.fields[attr].error = "duplicated";
          });
        }
      }
      if (
        item._draft.fields[attr].onchange &&
        typeof item._draft.fields[attr].onchange == "function"
      ) {
        item._draft.fields[attr].onchange(
          attr,
          item._draft.fields[attr].value,
          item
        );
      }
    }
    return item._draft.fields[attr].value;
  };

  // it resets the form, item or attribute
  reset = (item, attr) => {
    var p;
    const _runItem = (item, attr) => {
      this.prop(item, attr, item._draft.fields[attr].previous);
    };
    if (attr !== undefined && item != undefined) {
      _runItem(item, attr);
      return;
    }
    if (attr === undefined && item != undefined) {
      for (p in item._draft.fields) {
        _runItem(item, p);
      }
      return;
    }
    this.items.forEach((item) => {
      for (var p in item._draft.fields) {
        _runItem(item, p);
      }
    });
    for (var i in this.items || []) {
      for (p in this.items[i]._draft.fields) {
        _runItem(this.items[i], p);
      }
    }
  };

  // returns true if form or attribute has error
  error = (item, attr) => {
    var p;
    const _runItem = (item, attr) => item._draft.fields[attr].error;
    if (attr !== undefined && item != undefined) {
      return _runItem(item, attr);
    }
    if (attr === undefined && item != undefined) {
      for (p in item._draft.fields) {
        if (_runItem(item, p)) return true;
      }
      return false;
    }
    for (var i in this.items || []) {
      for (p in this.items[i]._draft.fields) {
        if (_runItem(this.items[i], p)) return true;
      }
    }
    return false;
  };

  isDirty = (item, attr) => {
    var p;
    const _runItem = (item, attr) => {
      return (
        item._draft.fields[attr].previous !== item._draft.fields[attr].value
      );
    };
    if (attr !== undefined && item != undefined) {
      return _runItem(item, attr);
    }
    if (attr === undefined && item != undefined) {
      for (p in item._draft.fields) {
        if (_runItem(item, p)) return true;
      }
      return false;
    }
    for (var i in this.items || []) {
      for (p in this.items[i]._draft.fields) {
        if (_runItem(this.items[i], p)) return true;
      }
    }
    return false;
  };
}

export default {
  name: "SearchableTable",
  components: {
    tho,
    SearchableTableQueryInput,
    Icon,
    draggable: () => import("vuedraggable"),
    ColumnOrderSelection: () =>
      import("@/components/column-order-selection.vue"),
    TablePaginationNav: () => import("@/components/table-pagination-nav.vue"),
    Spin
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => null
    },
    fields: {
      type: Array,
      required: false,
      default: () => null
    },
    commands: {
      type: Array,
      required: false,
      default: () => null
    },
    searchEnabled: {
      type: Boolean,
      default: () => true,
      required: false
    },
    deepSearch: {
      type: Boolean,
      default: () => true, // If true - input query string is searched on the raw item // false, searchs on table fields only
      required: false
    },
    showSelected: {
      type: Boolean,
      default: () => false,
      required: false
    },
    tableId: {
      type: String,
      default: () => "",
      required: false
    },
    sortDef: {
      type: Object,
      default: () => ({
        column: "",
        asc: true
      }),
      required: false
    },
    sortOnMount: {
      type: Boolean,
      default: () => true,
      required: false
    },
    draggable: {
      type: Object,
      default: () => null,
      required: false
    },
    multiColumnOrder: {
      type: Boolean,
      default: () => false,
      required: false
    },
    clientSort: {
      type: Boolean,
      default: () => true,
      required: false
    },
    pagination: {
      // enable/disable pagination
      type: [Boolean, String],
      required: false,
      default: () => false
    },
    maxResult: {
      // total items that can be displayed (if maxResult > nItems meant to be server side pagination engine)
      type: Number,
      required: false,
      default: () => 0
    },
    pageJump: {
      type: Boolean,
      required: false,
      default: false
    },
    aggregation: {
      type: Object,
      default: () => ({
        title: "",
        icons: {
          open: {
            class: "fa fa-chevron-down",
            color: "inherit"
          },
          close: {
            class: "fa fa-chevron-right",
            color: "inherit"
          }
        },
        style: {}
      }),
      required: false
    },
    visibleItems: {
      type: Boolean,
      required: false,
      default: false
    },
    searchClass: {
      type: String,
      default: ""
    },
    tableClass: {
      type: String,
      default:
        "table table-condensed table-bordered table-responsive table-hover table-striped",
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    multiSelection: {
      type: Object,
      required: false,
      default: () => ({ key: "", values: [] })
    }
  },
  data() {
    return {
      query: "",
      curPos: -1,
      sortedList: [],
      currentSort: {
        asc: true,
        column: ""
      },
      sortForm: false,
      pageItems: [],
      oppenedNodes: {}
    };
  },
  computed: {
    showPagination() {
      return (
        this.pagination &&
        this.pageSize &&
        (this.maxResult > this.pageSize || this.pagination == "server")
      );
    },
    pageSize() {
      return parseInt(
        (this.$root.config &&
          this.$root.config.equipment_selection &&
          this.$root.config.equipment_selection.page_size) ||
        0 // no pagination
      );
    },
    nItems() {
      // let count = this.maxResult || (this.itemList || []).length;
      // let count = 0;
      // if (this.maxResult) {
      //   count = this.filteredList.length;
      // }
      // return count;
      return (this?.filteredList || []).length;
    },
    itemList() {
      return this.pagination && this.pageSize && this.pageItems.length
        ? this.pageItems
        : this.pagination &&
          this.pageSize &&
          this.sortedList.length > this.pageSize
          ? this.sortedList.slice(0, this.pageSize)
          : this.sortedList;
    },
    nItemsForPagination() {
      return this.maxResult > this.sortedList.length
        ? this.maxResult
        : this.sortedList.length;
    },
    busy() {
      return this.items == null;
    },
    filteredList() {
      return this.search(this.items);
    },
    itemCommands() {
      return (this.commands || []).filter(function(i) {
        return i.name != "create";
      });
    },
    createCommand() {
      let lst = (this.commands || []).filter(function(i) {
        return i.name == "create";
      });
      return lst.length ? lst[0] : null;
    },
    bulkCommands() {
      return this.itemCommands.filter((c) => c.bulk);
    },
    groups() {
      return (
        this.fields.filter((f) => (f?.aggregation?.enabled ? true : false)) ||
        []
      ).map(({ name }) => name);
    },
    tree() {
      return this.groups.length
        ? this.$utils.tree(this.sortedList, this.fields)
        : null;
    },
    treeItems() {
      if (!this.groups.length) return null;
      let keys = {};
      const parse = (obj, key, parent) => {
        key = key || "";
        for (var k in obj) {
          let info = (key ? key + "." : "") + k;
          let level = info.split(".").length - 1;
          keys[info] = {
            name: info,
            level: level,
            title: this.groups[level], // get from field
            parent: parent || null,
            children: [],
            leaf: null
          };
          if (parent) {
            parent.children.push(info);
          }
          if ("length" in obj[k]) {
            keys[info].leaf = obj[k];
          } else {
            parse(obj[k], info, keys[info]);
          }
        }
      };
      parse(this.tree);
      keys = Object.keys(keys)
        .map((key) => ({ xk: key.toLowerCase(), key: key }))
        .sort((a, b) => (a.xk > b.xk ? 1 : b.xk > a.xk ? -1 : 0))
        .reduce((obj, i) => {
          obj[i.key] = keys[i.key];
          return obj;
        }, {});
      return keys;
    },
    isMultiSelection() {
      return this?.multiSelection?.key ? true : false;
    },
    checkAllClass() {
      return !this.isMultiSelection ||
        !(this?.multiSelection?.values || []).length
        ? "fa fa-square-o"
        : (this?.items || []).length == this.multiSelection.values.length
          ? "fa fa-check-square-o"
          : "fa fa-minus-square-o";
    }
  },
  watch: {
    busy: {
      immediate: true,
      handler(isBusy) {
        if (!isBusy) {
          if (this.sortOnMount) this.sortList();
          else this.sortedList = this.filteredList;
        }
      }
    },
    items(n, o) {
      // check if the values have been changed
      if (n && !isEqual(n, this.sortedList)) {
        this.$nextTick(() => {
          this.$set(this, "sortedList", n);
          if (o && this.query) {
            this.sortList();
          }
        });
      }
    },
    nItems: {
      handler(n) {
        this.$emit("nItems", n);
        // this.syncColumnResizeHandle();
      },
      immediate: true
    },
    sortDef: {
      deep: true,
      immediate: true,
      handler(n, o) {
        if (n && o && (n.asc != o.asc || n.column != o.column)) {
          this.currentSort.asc = this.sortDef.asc;
          this.currentSort.column = this.sortDef.column;
        }
      }
    },
    currentSort: {
      handler(n, o) {
        this.sortList();
      }
    },
    query() {
      this.sortList();
    },
    treeItems: {
      handler(n) {
        this.$emit("groupsUpdated", n);
      },
      deep: true,
      immediate: true
    },
    showSelected(n) {
      if (!n) {
        this.curPos = -1;
      }
    }
  },
  methods: {
    isVisible(field, item) {
      if ("visible" in field) {
        if (typeof field.visible == "function") {
          return field.visible(item);
        }
        return field.visible;
      }
      return true;
    },
    commandTitle(command, item) {
      if (command.title && typeof command.title == "function") {
        return command.title(item);
      }
      return this.$tc(command.title || command.name || "action", 1);
    },
    commandIcon(command, item) {
      if ("icon" in command) {
        if (typeof command.icon == "function") {
          return command.icon(item);
        }
        return command.icon;
      }
      return "fa fa-bolt";
    },
    isCommandEnabled(command, item) {
      if ("enabled" in command) {
        if (typeof command.enabled == "function") {
          return command.enabled(item || {});
        }
        return command.enabled;
      }
      return true;
    },
    isSelected(item) {
      return (
        this.isMultiSelection &&
        (this?.multiSelection?.values || []).some(
          (i) => i == item[this.multiSelection.key]
        )
      );
    },
    toggleSelection(ix, item) {
      if (ix == undefined || item === undefined) {
        if ((this?.multiSelection?.values || []).length) {
          this.$emit("select", []);
        } else {
          this.$emit(
            "select",
            (this?.filteredList || []).map((i) => i[this.multiSelection.key])
          );
        }
      } else {
        if (this.isMultiSelection) {
          if (this.isSelected(item)) {
            this.$emit(
              "select",
              (this?.multiSelection?.values || []).filter(
                (i) => i != item[this.multiSelection.key]
              )
            );
          } else {
            this.$emit(
              "select",
              (this?.multiSelection?.values || []).concat([
                item[this.multiSelection.key]
              ])
            );
          }
        } else {
          this.select(ix, item);
        }
      }
    },
    select(ix, item) {
      if (this.showSelected) {
        if (this.curPos > -1 && this.curPos == ix) {
          this.curPos = -1;
          this.$emit("unselect", item);
        } else {
          this.curPos = ix;
          this.$emit("select", item);
        }
      } else {
        this.$emit("select", item);
      }
    },
    sortList() {
      // tree only sort opened nodes
      this.sortedList = this.tree
        ? this.sortedList
        : this._sortList(this.filteredList);
    },
    _sortList(lst) {
      let self = this;
      let asc =
        typeof self.currentSort.asc != "boolean" ? true : self.currentSort.asc;
      let attr = self.currentSort.column || "";
      if (lst.length) {
        lst = lst.sort(function(a, b) {
          var field = self.field(attr);
          if (field) {
            if (field.parser) {
              if (
                asc
                  ? field.parser(a, self) > field.parser(b, self)
                  : field.parser(b, self) > field.parser(a, self)
              )
                return 1;
              if (
                asc
                  ? field.parser(b, self) > field.parser(a, self)
                  : field.parser(a, self) > field.parser(b, self)
              )
                return -1;
            } else {
              let colName = field ? field?._name || field?.name || attr : "";
              if (colName && colName in a && colName in b) {
                if (asc ? a[colName] > b[colName] : b[colName] > a[colName])
                  return 1;
                if (asc ? b[colName] > a[colName] : a[colName] > b[colName])
                  return -1;
              }
            }
          }
          return 0;
        });
      }
      return lst;
    },
    onButtonCreateClick(name) {
      this.curPos = -1;
      this.$emit("unselect");
      this.$emit("command", {
        name: name
      });
    },
    onButtonCommandClick(ix, command, item, el) {
      if (this.isCommandEnabled(command, item)) {
        this.curPos = ix;
        this.$emit("command", {
          name: command.name,
          target: item,
          el,
          index: ix,
          items: this.sortedList
        });
      }
    },
    onBulkCommand(command, el) {
      if (this.isCommandEnabled(command, this.sortedList)) {
        this.$emit("command", {
          name: command.name,
          el,
          items: this.sortedList,
          bulk: true
        });
      }
    },
    onSort(attr) {
      let currentSort = {};
      currentSort.asc =
        attr == this.currentSort.column ? !this.currentSort.asc : true;
      currentSort.column = attr;
      this.currentSort = currentSort;
      if (this.clientSort || !this.pagination) {
        this.$emit("reorder", this.currentSort);
      } else {
        let field = this.fields.find((i) => i.name == attr);
        let fieldName = (field && field.order_id) || attr;
        let columnName = (currentSort.asc ? "" : "-") + fieldName;
        this.$emit("multiColumnSort", [columnName]);
      }
    },
    onMultiColumnSort(columns) {
      // reset client sort:
      this.sortForm = false;
      this.currentSort.column = "";
      this.currentSort.asc = true;
      // trigger background sort
      this.$emit("multiColumnSort", columns);
    },
    field(name) {
      return (this.fields || []).find((item) => item.name == name);
    },
    getClass(item) {
      return item.class || "";
    },
    value(item, field) {
      let self = this;
      let value = "";
      if (item && field) {
        if (field.parser) {
          value = field.parser(item, self) ?? "";
        } else {
          value = item[field.name] ?? "";
        }
        if (field.format) {
          value = field.format(value, item);
        }
      }
      return value;
    },
    style(item, field) {
      if (field && "style" in field) {
        if (typeof field.style == "function") {
          return field.style(item || null);
        }
        return field.style;
      }
      return {};
    },
    hint(item, field) {
      if (field && "hint" in field) {
        if (typeof field.hint == "function") {
          return field.hint(item || null);
        }
        return field.hint;
      }
      return '';
    },
    onShowSortForm() {
      this.sortForm = true;
    },
    toggleTreeNode(key) {
      if (key in this.oppenedNodes) {
        Object.keys(this.oppenedNodes).forEach((k) => {
          if (k == key || k.startsWith(key + ".")) {
            this.$delete(this.oppenedNodes, k);
          }
        });
      } else {
        this.$set(this.oppenedNodes, key, true);
      }
    },
    isOpen(node) {
      return node.name in this.oppenedNodes;
    },
    fieldAggregation(node) {
      let field =
        this.fields.find(({ name }) => this.groups[node.level] == name) || null;
      return (field && field.aggregation) || this.aggregation;
    },
    nodeIcon(node) {
      return (
        this.fieldAggregation(node).icons[
        this.isOpen(node) ? "open" : "close"
        ] ||
        (this.isOpen(node)
          ? { class: "fa fa-chevron-down", color: "inherit" }
          : { class: "fa fa-chevron-right", color: "inherit" })
      );
    },
    monitorVisibleItems() {
      // it notifies parent with a list of visible element index
      // visibleItems = []
      if (!this.items.length) return;
      if (!this.$el) return;
      const tables = this.$el.getElementsByTagName("table");
      if (!tables || !tables.length) return;
      const $table = tables[0];
      const rows = $table.getElementsByTagName("tr");

      const isVisible = (row, view) => {
        let refTop = view.offsetTop + view.scrollTop;
        let refBottom = refTop + view.clientHeight;
        let rowTop = row.offsetTop;
        let rowBottom = rowTop + row.clientHeight;
        return rowBottom <= refBottom && rowTop >= refTop - 32; // threshold top (+- header)
      };

      let items = this.items.map(() => ({ status: "not_visited" }));

      const getVisibleItems = () => {
        return new Promise((resolve) => {
          let lst = [];
          if (rows || rows.length) {
            for (var ix in rows) {
              // skip table header
              if (ix > 0) {
                let $row = rows[ix];
                if ($row) {
                  if (items[ix - 1].status == "not_visited") {
                    if (isVisible($row, this.$el)) {
                      items[ix - 1].status = "visited";
                      lst.push(ix - 1);
                    }
                  }
                }
              }
            }
          }
          resolve(lst);
        });
      };

      const build = () => {
        return new Promise((resolve) => {
          getVisibleItems().then((lst) => {
            this.$emit("visibleItems", lst);
            resolve(lst);
          });
        });
      };

      const rebuild = debounce(build, 500);

      // first build
      build().then((lst) => {
        if (lst.length && lst.length != this.items.length) {
          this.$el.addEventListener("scroll", () => {
            rebuild();
          });
        }
      });
    },
    search(items) {
      if (items && items.length && this.query) {
        var qtags = this.$utils.hashtags(this.query, true);
        let lst = items.filter((item) => {
          if (qtags.tags.length && (item?.portal_data?.tags || []).length) {
            for (var i in item.portal_data.tags) {
              if (
                qtags.tags.indexOf(
                  (item.portal_data.tags[i]?.text || "").toLowerCase()
                ) >= 0
              ) {
                if (qtags.text) {
                  return this.$utils.queryStrAtr(qtags.text, item); // not deep
                } else {
                  return true;
                }
              }
            }
          }
          if (this.deepSearch) {
            return this.$utils.queryStrAtr(this.query, item, "any"); // deep search
          } else {
            let obj = {};
            for (var i in this.fields) {
              obj[this.fields[i].name] = this.fields[i].parser
                ? this.fields[i].parser(item, this)
                : item[this.fields[i].name];
            }
            return this.$utils.queryStrAtr(this.query, obj);
          }
        });
        return lst;
      }
      return items || [];
    },
    syncColumnResizeHandle() {
      this.$nextTick(() => {
        if (this.$refs.table) {
          var _rc = $(this.$refs.table).data("resizableColumns");
          if (_rc) {
            _rc.syncHandleWidths();
          } else {
            setTimeout(() => {
              if (this.$refs.table) {
                $(this.$refs.table).resizableColumns({
                  resizeFromBody: false
                });
              }
            }, 500, this);
          }
        }
      });
    },
    visibilityMonitor() {
      const onVisibilityChange = (el, cb) => {
        new IntersectionObserver((lst) => {
          lst.forEach((item) => cb(item.intersectionRatio > 0));
        },
          { root: document.documentElement }
        ).observe(el);
      }
      onVisibilityChange(this.$el, (op) => {
        // console.log(`${this.$parent.$options.name} ${op}`);
        this._isVisible = op;
        if (this._isVisible) {
          this.syncColumnResizeHandle();
        }
      });
    }
  },
  created() {
    this._isVisible = false;
    if (this.sortDef) {
      this.currentSort = JSON.parse(JSON.stringify(this.sortDef));
    }
  },
  mounted() {
    if (this.visibleItems) {
      this.monitorVisibleItems();
    }
    this.visibilityMonitor();
  }
};
</script>

<style scoped>
.searchable-table {
  clear: both;
}
.searchable-table > div > table {
  margin-bottom: 0;
  font-size: 96%;
}

.table-body {
  overflow: auto;
}
.bulk-command {
  vertical-align: initial;
}

.bulk-command .dropdown-toggle {
  float: initial;
  padding: 0px 7px;
  margin-top: -3px;
  font-size: 0.7em;
}

.clicable-row:hover {
  cursor: pointer;
}

.table-container {
  max-height: 87%;
  overflow-y: auto;
}

.table > tbody > tr > th,
.table > tbody > tr > td {
  vertical-align: middle;
}

.table > thead > tr > th > i.fa,
.table > thead > tr > td > i.fa,
.table > tbody > tr > th > i.fa,
.table > tbody > tr > td > i.fa {
  text-align: center;
  min-width: 18px;
}

.table > thead > tr > th:hover > i.fa,
.table > thead > tr > td:hover > i.fa,
.table > tbody > tr > th:hover > i.fa,
.table > tbody > tr > td:hover > i.fa {
  cursor: pointer;
  font-weight: 600;
}

.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
  background-color: #367fa9;
  color: white;
}

.draggable-th {
  width: 2rem;
  text-align: center;
  font-weight: bold;
}

.drag-handle {
  cursor: grab;
}

.drag-handle:active {
  cursor: grabbing;
}

.action-cell {
  padding-left: 10px;
  white-space: nowrap;
  text-align: center;
}

.action-cell button,
.action-cell .btn-sep {
  text-align: left;
}

.btn-action {
  margin: 0 5px;
  padding: 0 5px;
}

.btn-sep {
  display: inline-block;
  border-left: 1px solid #dcd9d9;
  margin: -8px 3px;
  min-height: 22px;
  max-width: 1px;
  padding: 0;
}
.overlay-local {
  position: relative;
}
.overlay-local i {
  z-index: 1;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

i.group-collapse-icon {
  color: #31708f;
}

tr.group-row {
  background: #aaa;
  color: #333;
  font-weight: 800;
  font-size: 12pt;
  padding: 4px 6px;
  border-bottom: 2px solid #fff;
}

tr.group-row > td {
  padding-top: inherit;
  padding-bottom: inherit;
}

tr.group-row > td:first-child {
  padding-left: inherit;
}
tr.group-row > td:last-child {
  padding-right: inherit;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.btn-action::v-deep svg {
  vertical-align: text-bottom;
}
</style>
